<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <section class="gray pt-4">
        <div class="container">
          <h2>About Us</h2>
          <div class="card" v-if="! loading">
            <div class="card-body" v-html="content"></div>
          </div>
          <loading v-if="loading" />
        </div>
      </section>

      <!-- ============================ Call To Action ================================== -->
      <call-to-action />
      <!-- ============================ Call To Action End ================================== -->

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    document.title = 'Discover RealHub Kenya - Your Trusted Partner for Finding Properties in Kenya - Learn More on Our About Us Page'
    
    this.fetch()
  },

  data() {
    return {
      content: '',
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/content/about').then(response => {
        this.content = response.data.content
        this.loading = false
      })
    }
  }
};
</script>
